<template>
  <div class="mx-auto max-w-screen-xl my-8">
    <p class="text-center text-4xl font-bold text-neutral-800 md:text-start">
      我們提供的資料，涵蓋
    </p>
  </div>
  <div
    class="mx-auto max-w-screen-xl my-4 flex flex-col gap-6 md:flex-row md:justify-between md:py-4"
  >
    <div class="flex items-center justify-center">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#000000"
          class="mx-5 h-20 w-20 stroke-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
          />
        </svg>
      </div>

      <div class="flex flex-col items-center justify-center">
        <div v-if="statisticsFetched" class="flex">
          <p class="mb-2 self-end text-5xl font-extrabold text-blue-700">
            {{ companyNumber }}
          </p>
          <span class="self-center text-blue-700">家</span>
        </div>
        <div v-else class="flex">
          <p class="mb-2 self-end text-5xl font-extrabold text-blue-700">存取中</p>
        </div>
        <p class="text-neutral-800">壽險公司</p>
      </div>
    </div>

    <div class="flex items-center justify-center">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#000000"
          class="mx-5 h-20 w-20 stroke-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
          />
        </svg>
      </div>

      <div class="flex flex-col items-center justify-center">
        <div v-if="statisticsFetched" class="flex">
          <p class="mb-2 self-end text-5xl font-extrabold text-blue-700">
            {{ fundHouseNumber }}
          </p>
          <span class="self-center text-blue-700">家</span>
        </div>
        <div v-else class="flex">
          <p class="mb-2 self-end text-5xl font-extrabold text-blue-700">存取中</p>
        </div>
        <p class="text-neutral-800">代操投信</p>
      </div>
    </div>

    <div class="flex items-center justify-center">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#000000"
          class="mx-5 h-20 w-20 stroke-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
          />
        </svg>
      </div>

      <div class="flex flex-col items-center justify-center">
        <div v-if="statisticsFetched" class="flex">
          <p class="mb-2 self-end text-5xl font-extrabold text-blue-700">
            {{ accountNumber }}
          </p>
          <span class="self-center text-blue-700">個</span>
        </div>
        <div v-else class="flex">
          <p class="mb-2 self-end text-5xl font-extrabold text-blue-700">存取中</p>
        </div>
        <p class="text-neutral-800">類全委帳戶</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStatisticsStore } from '@/stores/useAccountDescStore'

const statisticsStore = useStatisticsStore()
callOnce(statisticsStore.fetchStatistics)
const statisticsFetched = computed(() => statisticsStore.statisticsFetched || false)
const companyNumber = computed(() => statisticsStore.numberOfCompanies.companyNumber || 0)
const fundHouseNumber = computed(() => statisticsStore.numberOfFundHouses.fundHouseNumber || 0)
const accountNumber = computed(() => statisticsStore.numberOfAccounts.accountNumber || 0)
</script>
